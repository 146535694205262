import React, { useEffect, useState } from "react";
import { Button, Form, Drawer, message, Input, Row, Col, Select, Switch } from "antd";
import axios from "axios";
import { useForm } from "antd/es/form/Form";
import ReactQuill from "react-quill";

import endpoints from "../../utils/endpoints";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";

function Create({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [programs, setPrograms] = useState([]);

  const [form] = useForm();

  useEffect(() => {
    if (open) {
      console.log(data);
      form.setFieldsValue(data);

      axios
        .get(endpoints.user.read)
        .then((res) => {
          setUsers(res.data);
          axios
            .get(endpoints.program.read)
            .then((resProgram) => {
              let auxData = resProgram.data[0];
              const uniqueIds = [];

              const unique = auxData.filter((element) => {
                const isDuplicate = uniqueIds.includes(element.id);

                if (!isDuplicate) {
                  uniqueIds.push(element.id);

                  return true;
                }

                return false;
              });

              setPrograms(unique);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [open]);

  function handleClose() {
    form.resetFields();
    setIsButtonLoading(false);
    close();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    let removedUsers = [];
    let addedUsers = [];

    if (JSON.stringify(values.breakout_users) !== JSON.stringify(data.breakout_users)) {
      removedUsers = data.breakout_users.filter((x) => !values.breakout_users.includes(x));
      addedUsers = values.breakout_users.filter((x) => !data.breakout_users.includes(x));
    }

    axios
      .post(endpoints.breakouts.update, {
        data: {
          breakout: {
            id: values.id,
            name: values.name,
            subtitle: values.subtitle,
            description: values.description,
            id_program: values.id_program,
            is_active: values.is_active,
          },
          breakout_users: {
            added: addedUsers,
            removed: removedUsers,
          },
        },
      })
      .then((res) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
          form.resetFields();
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-user`}
      title="Edit breakout"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={[
        <Button size="large" disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
          Cancel
        </Button>,
        <Button size="large" loading={isButtonLoading} type="primary" onClick={form.submit}>
          Update
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input placeholder="Name..." size="large" />
        </Form.Item>
        <Form.Item name="subtitle" label="Subtitle">
          <Input placeholder="Subtitle..." size="large" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <ReactQuill placeholder="Description" className="title_react_quill" theme="snow" />
        </Form.Item>
        <Form.Item
          label="Users"
          name="breakout_users"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            placeholder="Users..."
            options={users.map((item) => ({ value: item.id, label: item.name }))}
            size="large"
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            getPopupContainer={(trigger) => trigger.parentNode}
            showSearch
            optionFilterProp="children"
          />
        </Form.Item>
        <Form.Item
          label="Program"
          name="id_program"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Select
            placeholder="Program..."
            options={programs.map((item) => ({ value: item.id, label: dayjs(item.date).format(`DD/MM/YYYY`) + " | " + item.title }))}
            size="large"
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            getPopupContainer={(trigger) => trigger.parentNode}
            showSearch
            optionFilterProp="children"
          />
        </Form.Item>
        <Form.Item label="Active" name="is_active" valuePropName="checked">
          <Switch size="large" />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default Create;
