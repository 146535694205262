import {
  Row,
  Col,
  Button,
  Form,
  notification,
  Divider,
  Pagination,
  Spin
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
  SendOutlined,
  InboxOutlined
} from '@ant-design/icons';
import { useEffect, useState } from "react";
import axios from 'axios';

import config from "../../utils/config";
import Create from "../../components/Notifications/Create";
import Update from "../../components/Notifications/Update";
import Delete from "../../components/Notifications/Delete";

import "./Notifications.css";
import endpoints from "../../utils/endpoints";

let tokenFirebase = "AAAA3v27RRA:APA91bHnRR-wBXsPAbVuJIpa85I_-rIWAnSD3jjMqvMVtZI38mP1x_nRON87L2YqeybFqpXR4tyGvk2U0KDWBuvDkT9DK5oI6llTsyuptNl99hDPbq-TWGGvz3ACHV9RfeXIgvzdy9Ys"
const maxDevices = 1000;

function Notifications() {

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(5);

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios.get(endpoints.notification.read).then((response) => {
      console.log(response.data)
      let notificationsData = [];
      for (let i = 0; i < response.data.length; i++) {
        let arrayData = response.data[i];
        let aux = {
          key: (i + 1),
          id: arrayData.id,
          title: arrayData.title,
          description: arrayData.description,
          actions: (
            <div className="actions-buttons-div">
              <SendOutlined className="edit-action" onClick={() => handleSendNotification(arrayData)} />
              <EditOutlined className="edit-action" onClick={() => handleOpenUpdate(arrayData)} />
              <DeleteOutlined className="delete-action" onClick={() => handleOpenDelete(arrayData)} />
            </div>
          )
        }

        notificationsData.push(aux);
      }

      setTableData(notificationsData);
      setData(response.data);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
    });
  }

  function handleSendNotification(data) {
    let tokens = []; /* Android tokens */
    let iTokens = []; /* iOS tokens */

    axios.get(endpoints.device.getDevices).then((res) => {
        let dataServer = res.data;
        for (let i = 0; i < dataServer.length; i++) {
          if (dataServer[i].platform === 'android') {
            tokens.push(dataServer[i].token);
          } else if (dataServer[i].platform === 'ios') {
            iTokens.push(dataServer[i].token);
          }
        }

        if (tokens.length > 0) {
          handleSendPushMessageAndroid(tokens, data.title, data.description, data.type, data.id, 'Fresenius - CDI');
        }

        if (iTokens.length > 0) {
          handleSendPushMessageIOS(iTokens, data.title, data.description, data.id, 'Fresenius - CDI');
        }


      }).catch((err) => {
        console.log(err);
      });
  }

  function handleSendPushMessageAndroid(tokens, title, description, id, name) {
    let url = 'https://fcm.googleapis.com/fcm/send';
    let data = {
      registration_ids: [],
      notification: {
        body: description,
        title: title
      },
      data: {
        id: id,
        sound: 'default',
        badge: 1,
        vibrate: 1,
        'image-type': "circle",
      }
    }

    let totalDevices = tokens.length;
    let totalFailure = 0;
    let todo = totalDevices / maxDevices;
    let done = 0;
    let pushTokens = [];

    pushTokens = tokens;

    while (done < maxDevices) {
      let totalTokens = pushTokens.length;
      data['registration_ids'].push(pushTokens[done]);
      // use key 'http' even if you send the request to https://...
      if (totalTokens <= maxDevices) {
        if (done === (totalTokens - 1)) {
          axios.post(url, data, {
            headers: {
              'Authorization': `key=${tokenFirebase}`,
              'Content-Type': 'application/json'
            }
          }).then((res) => {
            console.log(res);
            notification.success({
              message: `Notificações Android!`,
              description: 'Notificações Android lançadas com sucesso!'
            });
            console.log(res);
          }).catch((err) => {
            console.log(err);
          });

          break;
        }

        done++;
      } else {
        if (done === (maxDevices - 1)) {
          axios.post(url, data, {
            headers: {
              'Authorization': `key=${tokenFirebase}`,
              'Content-Type': 'application/json'
            }
          }).then((res) => {
            console.log('Ainda faltam mais notificações...');
          }).catch((err) => {
            console.log(err);
          });
        }

        if (done === (maxDevices - 1)) {
          data['registration_ids'] = [];
          pushTokens = pushTokens.slice(maxDevices);
          done = 0;
        } else {
          done++;
        }
      }
    }
  }

  function handleSendPushMessageIOS(tokens, title, description, type, id, name, image) {
    let url = 'https://fcm.googleapis.com/fcm/send';
    let data = {
      registration_ids: [],
      priority: "high",
      notification: {
        body: description,
        title: title
      },
      data: {
        id: id,
        sound: 'default',
        badge: 1,
        vibrate: 1,
      }
    }

    let totalDevices = tokens.length;
    let totalFailure = 0;
    let todo = totalDevices / maxDevices;
    let done = 0;
    let pushTokens = [];

    pushTokens = tokens;

    while (done < maxDevices) {
      let totalTokens = pushTokens.length;
      data['registration_ids'].push(pushTokens[done]);
      console.log(data['registration_ids']);
      // use key 'http' even if you send the request to https://...
      if (totalTokens <= maxDevices) {
        if (done === (totalTokens - 1)) {
          axios.post(url, data, {
            headers: {
              'Authorization': `key=${tokenFirebase}`,
              'Content-Type': 'application/json'
            }
          }).then((res) => {
            console.log(res);
            notification.success({
              message: `Notificações iOS!`,
              description: 'Notificações iOS lançadas com sucesso!'
            });
            console.log(res);
          }).catch((err) => {
            console.log(err);
          });

          break;
        }

        done++;
      } else {
        if (done === (maxDevices - 1)) {
          axios.post(url, data, {
            headers: {
              'Authorization': `key=${tokenFirebase}`,
              'Content-Type': 'application/json'
            }
          }).then((res) => {
            console.log('Ainda faltam mais notificações...');
          }).catch((err) => {
            console.log(err);
          });
        }

        if (done === (maxDevices - 1)) {
          data['registration_ids'] = [];
          pushTokens = pushTokens.slice(maxDevices);
          done = 0;
        } else {
          done++;
        }
      }
    }
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0)
      setMaxValue(itemsPerPage)
    } else {
      let newMinValue = (itemsPerPage * (e - 1));
      let newMaxValue = newMinValue + itemsPerPage
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);  
    }
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    setSelectedData({});
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    setSelectedData({});
    handleGetData();
  }


  return (
    <div className='dashboard_page'>
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Row className='title_row mb-20 dashboard_title_row'>
        <Col span={12} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Notificações</h3>
        </Col>
        <Col span={12} className="d-flex jc-end ai-center">
          <Button size="large" onClick={handleOpenCreate}>Create</Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col xs="24" xl={24}>
            {tableData.length > 0 ?
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.slice(minValue, maxValue).map((item) => {
                    return (
                      <Row className="table_item">
                        <Col span={11}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Título</span>
                            <span className="f-18 semi-bold">{item.title}</span>
                          </div>
                        </Col>
                        <Col span={11} className="d-flex jc-start ai-center">
                          <div className="d-flex flex-column">
                            <span className="f-12">Descrição</span>
                            <span className="d-flex ai-center">{item.description}</span>
                          </div>
                        </Col>
                        <Col span={2} className="d-flex jc-end ai-center">
                          {item.actions}
                        </Col>
                      </Row>
                    )
                  })
                  }
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination onChange={handleChangePage} pageSize={itemsPerPage} defaultCurrent={1} current={currentPage} total={data.length} />
                </div>
              </div>
              :
              <Row className="d-flex jc-center ai-center empty_data">
                <Col span={24} className="d-flex flex-column jc-center ai-center">
                  <InboxOutlined />
                  <span className="f-20 mt-20 color-empty">This table is empty</span>
                </Col>
              </Row>
            }
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Notifications;
