import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Table,
  Input,
  Form
} from 'antd';
import { EditOutlined, DeleteOutlined, ReloadOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import axios from "axios";
import dayjs from "dayjs";

import Update from "../../components/Transfers/Update";
import Create from "../../components/Transfers/Create";
import Delete from "../../components/Transfers/Delete";
import endpoints from "../../utils/endpoints";

import './Transfers.css'
import { FiFile, FiMapPin } from "react-icons/fi";
import config from "../../utils/config";

const Transfers = () => {

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'File',
      dataIndex: 'file',
      width: 40,
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 160
    },
  ];

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios.get(endpoints.transfer.read)
      .then(res => {
        console.log(res);
        setData(res.data);
        handlePrepareData(res.data);
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error)
      });
  }

  function handlePrepareData(data) {
    setIsLoading(true);
    let aux = [];
    for (let i = 0; i < data.length; i++) {
      let auxObj = {
        id: data[i].id,
        name: data[i].name,
        description: <div dangerouslySetInnerHTML={{ __html: data[i].description }}></div>,
        file: data[i].file ? <Button onClick={() => window.open(`${config.server_ip}files/${data[i].file}`)}><FiFile /></Button> : null,
        is_active: data[i].is_active ? <CheckCircleOutlined /> : <CloseCircleOutlined />,
        actions:
          <div className="d-flex jc-end">
            <Button onClick={() => handleOpenUpdate(data[i])}><EditOutlined /></Button>
            {!data[i].is_admin &&
              <Button className="ml-10" onClick={() => handleOpenDelete(data[i])}><DeleteOutlined /></Button>
            }
          </div>,
        full_data: data[i]
      }

      aux.push(auxObj);
    }

    setIsLoading(false);
    setTableData(aux);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetData();
  }

  function handleSearch(e) {
    console.log(e);
    let searchString = e.search;
    let filterData = data.filter((item) => item.email.includes(searchString) || item.name.toLowerCase().includes(searchString));
    handlePrepareData(filterData)
  }

  return (
    <div className='dashboard_page'>
      <Row>
        <Create open={isOpenCreate} close={handleCloseCreate} />
        <Update open={isOpenUpdate} close={handleCloseUpdate} data={selectedData} />
        <Delete open={isOpenDelete} close={handleCloseDelete} data={selectedData} />
        <Col span={24}>
          <Row className='title_row mb-20 dashboard_title_row'>
            <Col span={6}>
              <h3 className="mt-0 mb-0">Transfers</h3>
            </Col>
            <Col span={18}>
              <div className="d-flex jc-end ai-center">
                <Form onValuesChange={handleSearch} style={{ maxWidth: 400, borderRadius: 0 }}>
                  <Form.Item name="search" className="mb-0 mr-10" >
                    <Input size="large" className="" style={{ maxWidth: 400, borderRadius: 0 }} placeholder="Procurar..." />
                  </Form.Item>
                </Form>
                <Button className="mr-10" size="large" onClick={handleGetData}><ReloadOutlined /></Button>
                <Button size="large" onClick={handleOpenCreate}>Create</Button>
              </div>
            </Col>
          </Row>
          <Row className="dashboard_content_row">
            <Col span={24}>
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={tableData}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Transfers;