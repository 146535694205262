import { Layout, Row, Col, Spin } from "antd";
import { HeartFilled } from "@ant-design/icons";
import logoImage from '../../assets/images/Care-Delivery-International.svg'
import './Loading.css'

export default function Loading() {

  return (
    <div className="loading_div">
      <div className="logo">
        <img src={logoImage} />
      </div>
      <Spin spinning={true} />
    </div>
  );
}
