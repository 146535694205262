import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  Steps
} from 'antd';
import { useForm } from "antd/es/form/Form";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import dayjs from "dayjs";

import ChooseColumns from "./ChooseColumns";
import ExportData from "./ExportData";

function DrawerExportTable({ open, close, data }) {

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);
  const [columnsToExport, setColumnsToExport] = useState([]);
  const [current, setCurrent] = useState(0);

  const [form] = useForm();

  useEffect(() => {
    let aux = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].full_data) {
        data[i].full_data.birth_date = dayjs(data[i].full_data.birth_date).format('YYYY-MM-DD');
        aux.push(data[i].full_data);
      } else {
        aux.push(data[i])
      }
    }

    setDataToExport(aux);
  }, [data]);

  function handleClose() {
    close();
    setCurrent(0);
    form.resetFields();
  }

  function handleExport() {
    let fileName = `${dayjs().format('YYYY-MM-DD')}_${dayjs().format('HHmmss')}_UsersExport_CDI.xlsx`;
    let exportData = [];

    const headers = columnsToExport.map(column => column.title);
    exportData.push(headers);

    dataToExport.forEach(row => {
      let rowData = columnsToExport.map(column => {
        let value = row[column.dataIndex || column.key];
        if (typeof value === 'object' && value?.props && value.props?.children) {
          value = value?.props?.children;
        }
        return value;
      });
      exportData.push(rowData);
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
    const file = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(file, fileName);
  };

  function handleChooseColumns(e) {
    let auxColumns = e.columns;
    let aux = [];
    for (let i = 0; i < auxColumns.length; i++) {
      aux.push({
        title: auxColumns[i],
        dataIndex: auxColumns[i]
      })
    }

    setColumnsToExport(aux);
    setCurrent(1);
  }

  function handleChangeStep(e) {
    setCurrent(e);
  }


  return (
    <Drawer
      className="drawer-banner"
      key={`drawer-create-banner`}
      title="Exportar utilizadores"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button className="mr-10" onClick={handleClose}>Cancel</Button>
          {current === 0 &&
            <Button type="primary" onClick={form.submit}>
              Seguinte
            </Button>
          }
          {current === 1 &&
            <>
              <Button type="primary mr-10" onClick={() => setCurrent(0)}>
                Anterior
              </Button>
              <Button type="primary" onClick={handleExport}>
                Exportar
              </Button>
            </>
          }
        </>
      }
    >
      <Steps
        current={current}
        onChange={handleChangeStep}
        className="register-steps"
        items={[
          {
            title: 'Escolher colunas',
          },
          {
            title: 'Exportar dados',
          },
        ]}
      />
      {current === 0 &&
        <ChooseColumns form={form} handleSubmit={handleChooseColumns} data={dataToExport} />
      }
      {current === 1 &&
        <ExportData data={dataToExport} columns={columnsToExport} />
      }
    </Drawer>
  );
};

export default DrawerExportTable;