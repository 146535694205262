const endpoints = {
  auth: {
    login: 'auth/login',
    register: 'auth/register',
    verifyUser: 'auth/verifyUser',
    verifyToken: 'auth/verifyToken',
    renewToken: 'auth/renewToken',
    resetPasswordEmail: 'auth/resetPasswordEmail',
    resetPassword: 'auth/resetPassword',
  },
  settings: {
    read: 'settings/read',
    update: 'settings/update',
  },
  device: {
    getDevice: 'device/readByToken',
    getDevices: 'device/read',
    create: 'device/create',
  },
  program: {
    read: '/program/read',
    readyById: 'program/readById',
    create: 'program/create',
    update: 'program/update',
    delete: 'program/delete'
  },
  notification: {
    read: 'notification/read',
    readById: 'notification/readyById',
    create: 'notification/create',
    update: 'notification/update',
    delete: 'notification/delete'
  },
  user: {
    create: 'user/create',
    read: 'user/read',
    update: 'user/update',
    delete: 'user/delete',
    readFileImport: 'user/readFileImport',
    import: 'user/import',
  },
  location: {
    create: 'location/create',
    read: 'location/read',
    update: 'location/update',
    delete: 'location/delete',
  },
  voting: {
    create: 'voting/create',
    read: 'voting/read',
    update: 'voting/update',
    delete: 'voting/delete',
  },
  breakouts: {
    create: 'breakouts/create',
    read: 'breakouts/read',
    update: 'breakouts/update',
    delete: 'breakouts/delete',
  },
  building: {
    create: 'building/create',
    read: 'building/read',
    update: 'building/update',
    delete: 'building/delete',
  },
  evaluation: {
    create: 'evaluation/create',
    read: 'evaluation/read',
    readAll: 'evaluation/readAll',
    update: 'evaluation/update',
    delete: 'evaluation/delete',
  },
  pco: {
    create: 'pco/create',
    read: 'pco/read',
    update: 'pco/update',
    delete: 'pco/delete',
  },
  transfer: {
    create: 'transfer/create',
    read: 'transfer/read',
    update: 'transfer/update',
    delete: 'transfer/delete',
  },
  faqs: {
    create: 'faqs/create',
    read: 'faqs/read',
    update: 'faqs/update',
    delete: 'faqs/delete',
  },
}

export default endpoints;