import React, { useEffect, useState } from "react";
import { Button, Form, Drawer, message, Input, Row, Col, Select, Switch } from "antd";
import axios from "axios";
import { useForm } from "antd/es/form/Form";
import ReactQuill from "react-quill";

import endpoints from "../../utils/endpoints";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";

function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [programs, setPrograms] = useState([]);

  const [form] = useForm();

  useEffect(() => {
    if (open) {
      axios
        .get(endpoints.user.read)
        .then((res) => {
          let auxUsers = res.data;

          setUsers(auxUsers.sort((a, b) => a.name.localeCompare(b.name)));
          axios
            .get(endpoints.program.read)
            .then((resProgram) => {
              let auxData = resProgram.data[0];
              const uniqueIds = [];

              const unique = auxData.filter((element) => {
                const isDuplicate = uniqueIds.includes(element.id);

                if (!isDuplicate) {
                  uniqueIds.push(element.id);

                  return true;
                }

                return false;
              });

              setPrograms(unique);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [open]);

  function handleClose() {
    form.resetFields();
    setIsButtonLoading(false);
    close();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios
      .post(endpoints.breakouts.create, {
        data: {
          breakout: {
            name: values.name,
            description: values.description,
            id_program: values.id_program,
          },
          breakout_users: values.breakout_users,
        },
      })
      .then((res) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
          form.resetFields();
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-vote`}
      title="Create breakout"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={[
        <Button size="large" disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
          Cancel
        </Button>,
        <Button size="large" loading={isButtonLoading} type="primary" onClick={form.submit}>
          Create
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input placeholder="Name..." size="large" />
        </Form.Item>
        <Form.Item name="subtitle" label="Subtitle">
          <Input placeholder="Subtitle..." size="large" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <ReactQuill placeholder="Description" className="title_react_quill" theme="snow" />
        </Form.Item>
        <Form.Item
          label="Users"
          name="breakout_users"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            placeholder="Users..."
            options={users.map((item) => ({ value: item.id, label: item.name }))}
            size="large"
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            getPopupContainer={(trigger) => trigger.parentNode}
            showSearch
            optionFilterProp="children"
          />
        </Form.Item>
        <Form.Item
          label="Program"
          name="id_program"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Select
            placeholder="Program..."
            options={programs.map((item) => ({ value: item.id, label: dayjs(item.date).format(`DD/MM/YYYY`) + " | " + item.title }))}
            size="large"
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            getPopupContainer={(trigger) => trigger.parentNode}
            showSearch
            optionFilterProp="children"
          />
        </Form.Item>
        <Form.Item label="Active" name="is_active" valuePropName="checked">
          <Switch size="large" />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default Create;
