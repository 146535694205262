import React, { useState, useEffect } from "react";
import { Button, Form, Drawer, Select, message, Upload, Input, Row, Col } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import Dragger from "antd/es/upload/Dragger";

import endpoints from "../../utils/endpoints";
import config from "../../utils/config";

function Update({ open, close, data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filePreview, setFilePreview] = useState("");

  const [form] = useForm();

  const uploadProps = {
    name: "file",
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilePreview("");
    },
    beforeUpload: (file) => {
      handlePreview(file, "image");
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    defaultFileList: [],
  };

  useEffect(() => {
    console.log(data);
    if (Object.keys(data).length > 0) {
      delete data.password;
      data.birth_date = dayjs(data.birth_date);
      if (data.img) {
        if (typeof data.img === "string") {
          console.log(data.img);
          setFilePreview(data.img);
          setFileList([{ uid: "not_new", name: data.img }]);
          data.img = [{ uid: "not_new", name: data.img }];
        }
      }
      form.setFieldsValue(data);
    }
  }, [data]);

  function handleClose() {
    form.resetFields();
    close();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    let formData = new FormData();

    if (fileList.length > 0) {
      fileList.forEach((file) => {
        if (file.uid !== "not_new") {
          formData.append("file", file);
        } else {
          values.img = file.name;
        }
      });
    } else {
      values.img = null;
    }

    console.log(values);

    if (!values.password) {
      delete values.password;
    }

    formData.append("data", JSON.stringify(values));

    fetch(`${config.server_ip}${endpoints.user.update}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((resData) => {
        console.log(resData);
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
          form.resetFields();
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  function handleGeneratePassword() {
    let chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let passwordLength = 8;
    let password = "";
    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }

    form.setFieldValue("password", password);
  }

  function normFile(e) {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  }

  async function handlePreview(file, type) {
    let filePreview = await getBase64(file);
    setFilePreview(filePreview);
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-user`}
      title="Update user"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={[
        <Button size="large" disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
          Cancel
        </Button>,
        <Button size="large" loading={isButtonLoading} type="primary" onClick={form.submit}>
          Update
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="img" valuePropName="fileList" getValueFromEvent={normFile}>
          <Dragger {...uploadProps} className={`user-img-dragger dragger ${filePreview ? "hasPreview" : ""}`}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <div
              className={`preview_file ${filePreview ? "hasPreview" : ""}`}
              style={{ backgroundImage: `url(${filePreview && filePreview.includes("base64") ? filePreview : `${config.server_ip}media/${filePreview}`})` }}
            ></div>
          </Dragger>
        </Form.Item>
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input placeholder="Nome" size="large" />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "This field is required",
            },
          ]}
        >
          <Input placeholder="Nome" size="large" />
        </Form.Item>
        <Row gutter={[24]}>
          <Col span={18}>
            <Form.Item className="mb-0" label="Password" name="password">
              <Input.Password placeholder="Password" size="large" />
            </Form.Item>
          </Col>
          <Col span={6} className="d-flex jc-end ai-end">
            <Button size="large" className="minh-38 maxh-38 f-14" type="primary" onClick={handleGeneratePassword}>
              Generate Password
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default Update;
