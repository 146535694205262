import { Breadcrumb, Layout, Menu, theme, Row, Col, Button } from 'antd';
import { useState, useEffect } from 'react';
import {
  Outlet,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Link } from 'react-router-dom';
import { DashboardOutlined, FileOutlined, FileTextOutlined, LogoutOutlined, NotificationOutlined, MailOutlined, SettingOutlined, TeamOutlined, UserOutlined, QuestionCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../utils/config';
import Loading from '../Loading/Loading';
import './Main.css';
import endpoints from '../../utils/endpoints';
import { FiAirplay, FiMapPin, FiThumbsUp } from 'react-icons/fi';

const { Content, Sider, Header } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem('PROGRAM', 'program', <FileOutlined />),
  getItem('USERS', 'users', <FileOutlined />),
  getItem('LOCATIONS', 'locations', <FiMapPin />),
  getItem('VOTING', 'voting', <FiThumbsUp />),
  getItem('BREAKOUTS', 'breakouts', <FiThumbsUp />),
  getItem('BUILDING', 'building', <FiThumbsUp />),
  getItem('EVALUATION', 'evaluation', <FiThumbsUp />),
  getItem('TRANSFERS', 'transfers', <FiAirplay />),
  getItem('FAQS', 'faqs', <QuestionCircleOutlined />),
  getItem('NOTIFICATIONS', 'notifications', <NotificationOutlined />),
  getItem('OFFICIAL AGENCY', 'pco', <InfoCircleOutlined />),
  getItem('SETTINGS', 'settings', <SettingOutlined />),
];

const MainDashboard = ({ handleLogout }) => {

  const location = useLocation();
  let pathname = location.pathname.split('/');

  const [user, setuser] = useState({});
  const [current, setCurrent] = useState(pathname[pathname.length - 1] === "" ? "/" : `/${pathname[pathname.length - 1]}`);
  const [isLoading, setIsLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let tokenData = localStorage.getItem('token');
    if (tokenData) {
      axios.post(endpoints.auth.verifyToken, {
        data: tokenData
      }).then((res) => {
        if (res.data.token_valid) {
          setuser(res.data.token_decoded)
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        } else {
          navigate('/login');
        }
      }).catch((err) => {
        console.log(err);
        navigate('/login');
      })
    } else {
      navigate('/login');
    }
  }, []);

  function handleClickMenu(e) {
    if (e.key === 'logout') {
      handleLogout()
    } else {
      navigate(e.key)
    }
  }

  return (
    <>
      {isLoading ?
        <Loading />
        :
        <Layout className='admin_layout'>
          <Sider
            width={250}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            <div className='logo' onClick={() => navigate('/')}>
            </div>
            <Menu theme="dark" defaultSelectedKeys={[current]} mode="inline" items={items} onClick={handleClickMenu} />
          </Sider>
          <Layout className="site-layout">
            <Header style={{ padding: 0, background: '#001529' }}>
              <Row className='h-100'>
                <Col span={12}></Col>
                <Col span={12} className='d-flex jc-end ai-center'>
                  <div className='user_header'>
                    <UserOutlined />{user.name}
                  </div>
                  <div className='logout_header'>
                    <Button type="text" icon={<LogoutOutlined />} onClick={handleLogout}> Terminar Sessão</Button>
                  </div>
                </Col>
              </Row>
            </Header>
            <Content
              className="admin_layout_content"
            >
              <Outlet context={user} />
            </Content>
          </Layout>
        </Layout>
      }
    </>
  )
};
export default MainDashboard;